import React from "react";
import flag from "../asset/india.jpeg";
import { heroWallpaper } from "../data/heroWallpaper";
import HeroWallpaper from "./HeroWallpaper";

const ContactInfo = () => {
  const heroWallpaperData = heroWallpaper;
  return (
    <div>
      <HeroWallpaper data={heroWallpaperData.contactInfoPageHeroWallpaper} />
      <div class="container p-5  make-center">
        <div class="col-sm-6 mb-4 ">
          <div class="card border-0" style={{ minHeight: "40rem" }}>
            <div class="card-body aboutcard-box-shadow p-4 make-center ">
              <div>
                <img src={flag} alt="Avatar" className="avatar" />
                <h5 class="card-title mt-4 make-center mb-0">INDIA</h5>
              </div>

              <p class="card-text">
                <p>
                  <strong>HEAD OFFICE:</strong> B-22, SARITA VISHWA KARMA NAGAR,
                  NEHRU ROAD MULUND WEST MUMBAI - 400080, MAHARASHTRA, INDIA.
                </p>
                <p>
                  <strong>Branch:</strong> J-1/C MAHAVIR NAGAR, M M PARK, NEAR
                  VIKAS DUDH FEDERATION, JALGAON - 425001, MAHARASHTRA, INDIA.
                </p>
                <p>
                  <strong>FACTORY:</strong> V-123/1,MIDC,JALGAON-425001.
                </p>

                <p>
                  <strong>Call us:</strong> 0257-2242471 (TELEPHONE) |
                  +918421652471 (MOBILE )
                </p>
                <p>
                  <strong>Mail Us:</strong>{" "}
                  <a href="mailto:  laxmisurgicals@yahoo.co.in">
                    {" "}
                    laxmisurgicals@yahoo.co.in
                  </a>
                </p>
              </p>
            </div>
          </div>
        </div>
        <div class="pt-4">
          <h5>
            Laxmi Surgicals India - Follow these steps to reach us very easily
          </h5>
          <p>
            <ol>
              <li>Open Google Maps on your smart phone</li>
              <li>
                Copy these numbers only 18.578750,73.887998 and paste in the
                search box. These numbers are our geolocation. Hit Search
                button.
              </li>
              <li>
                Follow the driving directions. You will precisely reach at our
                door step without asking anyone.
              </li>
              <li>You're Welcome :)</li>
            </ol>
          </p>
          <hr />
        </div>
      </div>
      <div class="container mt-4" style={{ width: "60%", paddingTop: "4%" }}>
        <div class="row pt-40">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1ZkGnuDPcUqzhwxBwFlO-l_1Ge2ixQv4&ehbc=2E312F"
              width="100%"
              height="480"
              frameborder="0"
              title="map"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
