import React from "react";

export default function HeroWallpaper({ data }) {
  return (
    <div>
      <div
        class="card "
        style={{
          marginTop: "65px",
        }}
      >
        <div style={{ overflow: "hidden", height: "28rem" }}>
          <img
            src={data.imageURL}
            width="100%"
            class="card-img-top quality-wallpaper blur-background"
            alt="abc"
          />
          <div class="card-img-overlay card-inverse ">
            {/* <h4 class="text-stroke text-white h2">{data?.title}</h4> */}
            <div
              class="card-block pt-2 ml-2 make-center"
              style={{
                height: "100%",
                alignItems: "center",
                position: "relative",
              }}
            >
              {/* <a
                href="/"
                class="card-link text-white text-size-2 h5"
                style={{ textDecoration: "none" }}
              >
                Home
              </a> */}
              <a
                class="card-link text-white text-size-2 h5"
                style={{ textDecoration: "none",fontSize:"5rem" }}
                href="/"
              >
                {data?.title}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
