export const heroWallpaper = {
  qualityHeroWallpaper: {
    title: "Certificates",
    imageURL: require("../asset/HeroWallpaper/quality.jpg"),
  },
  pricingPageHeroWallpaper: {
    title: "Gallery ",
    imageURL: require("../asset/HeroWallpaper/pricing.jpg"),
  },
  productsPageHeroWallpaper: {
    title: "Products",
    imageURL: require("../asset/HeroWallpaper/product.jpg"),
  },
  contactInfoPageHeroWallpaper: {
    title: "Contact information",
    imageURL: require("../asset/map.jpeg"),
  },
};
