import MenuIcon from "@mui/icons-material/Menu";
import { ClickAwayListener, Popover } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Outlet, useNavigate } from "react-router";
import "./MainNavigation.css";
import MobileViewMenuItems from "./MobileViewMenuItems";

const pages = ["Home", "ABOUT", "PRODUCT", "CERTIFICATES", "CONTACT", "Gallery"];

function MainNavigation() {
  let navigation = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setOpenDrawer(true);
  };

  const closeLeftDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCloseNavMenu = (page) => {
    if (page === "Home") {
      navigation(`/`);
    } else if (page === "Enquiry") {
      navigation("/CONTACT");
    } else if (page === "Our address") {
      navigation("/CONTACTINFO");
    } else if (page !== "mobile") {
      navigation(`/${page}`);
    }
    handlePopoverClose();
  };

  const ProductMenuItems = () => (
    <div>
      {["Enquiry", "Our address"].map((page) => (
        <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
          <Typography textAlign="center">{page}</Typography>
        </MenuItem>
      ))}
    </div>
  );

  const handlePopoverOpen = (event) => {
    if (event.target.textContent === "CONTACT")
      setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <AppBar
        position="fixed"
        className="mainNavigation-navbar header"
        style={{ backgroundColor: "#20ABD2" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <div class="make-center" style={{ width: "15rem" }}>
                <img
                  src={require("../../asset/Logo/logoWithoutbg.png")}
                  width="100px"
                  alt="logo"
                />
              </div>
            </Typography>

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                display: { xs: "flex", md: "none" },
              }}
            >
              <div class="make-center" style={{ width: "8rem" }}>
                <img
                  src={require("../../asset/Logo/logoWithoutbg.png")}
                  width="100px"
                  alt="logo"
                />
              </div>
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <MobileViewMenuItems
                closeLeftDrawer={closeLeftDrawer}
                openDrawer={openDrawer}
                navigation={navigation}
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  onClick={() => handleCloseNavMenu(page)}
                  onMouseEnter={handlePopoverOpen}
                  aria-haspopup="true"
                  sx={{
                    my: 2,
                    color: "#FFF",
                    display: "block",
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <ClickAwayListener onClickAway={handlePopoverClose}>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "painted",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <div class="row">
                  <div class="col-md-3">
                    {" "}
                    <ProductMenuItems />
                  </div>
                </div>
              </Popover>
            </ClickAwayListener>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </div>
  );
}

export default MainNavigation;
