import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div>
      <div
        class="headingCenter"
        style={{
          background: "#191919",
          color: "#FFF",
          fontSize: "14px",
        }}
      >
        <div
          style={{
            marginTop: "5%",
            width: "85%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ flexGrow: 1 }}
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
          >
            <Grid
              container
              spacing={2}
              columns={16}
              sx={{ justifyContent: "space-around" }}
            >
              <Grid item xs={10} md={7}>
                <h4>Laxmi Surgicals</h4>
                <hr className="bottom-line-left" />
                <p>
                  <strong>HEAD OFFICE:</strong> B-22, SARITA VISHWA KARMA NAGAR,
                  NEHRU ROAD MULUND WEST MUMBAI - 400080, MAHARASHTRA, INDIA.
                </p>
                <p>
                  <strong>Branch:</strong> J-1/C MAHAVIR NAGAR, M M PARK, NEAR
                  VIKAS DUDH FEDERATION, JALGAON - 425001, MAHARASHTRA, INDIA.
                </p>
                <p>
                  <strong>FACTORY:</strong> V-123/1,MIDC,JALGAON-425001.
                </p>

                <p>
                  <strong>Call us:</strong> 0257-2242471 (TELEPHONE) |
                  +918421652471 (MOBILE )
                </p>
                <p>
                  <strong>Mail Us:</strong>{" "}
                  <a href="mailto:  laxmisurgicals@yahoo.co.in">
                    {" "}
                    laxmisurgicals@yahoo.co.in
                  </a>
                </p>
              </Grid>
              <Grid item xs={8} md={2}>
                <h4>Certificates</h4>
                <hr className="bottom-line-left" />
                <p style={{ textDecoration: "none" }}>
                  <a
                    href={require("../../asset/Certificates/recognitionCertificate.pdf")}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#FFF" }}
                  >
                    Certificate of Recognition
                  </a>
                </p>

                <p style={{ textDecoration: "none" }}>
                  <a
                    href={require("../../asset/Certificates/IceCode.pdf")}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#FFF" }}
                  >
                    Importer-Exporter Code
                  </a>
                </p>

                <p style={{ textDecoration: "none" }}>
                  <a
                    href={require("../../asset/Certificates/LSLEI.pdf")}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#FFF" }}
                  >
                    Entity Identifier
                  </a>
                </p>
                <p style={{ textDecoration: "none" }}>
                  <a
                    href={require("../../asset/Certificates/SmallUdyamRegistrationCertificate.pdf")}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#FFF" }}
                  >
                    Udyam Registraion Certificate
                  </a>
                </p>
                <p style={{ textDecoration: "none" }}>
                  <a
                    href={require("../../asset/Certificates/ISO.pdf")}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#FFF" }}
                  >
                    ISO
                  </a>
                </p>
                <p style={{ textDecoration: "none" }}>
                  <a
                    href={require("../../asset/Certificates/CVC Certificate LSP.jpg")}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#FFF" }}
                  >
                    CVC
                  </a>
                </p>
              </Grid>
              <Grid item xs={8} md={2}>
                <h4>Company</h4>
                <hr className="bottom-line-left" />
                <p>
                  <a style={{ textDecoration: "none", color: "#FFF" }} href="/">
                    Home
                  </a>
                </p>
                <p>
                  <a
                    style={{ textDecoration: "none", color: "#FFF" }}
                    href="/ABOUT"
                    rel="noreferrer"
                  >
                    About
                  </a>
                </p>
                <p>
                  {" "}
                  <a
                    style={{ textDecoration: "none", color: "#FFF" }}
                    href="/PRODUCT"
                  >
                    Product
                  </a>
                </p>
                <p>
                  {" "}
                  <a
                    style={{ textDecoration: "none", color: "#FFF" }}
                    href="/CERTIFICATES"
                  >
                    CERTIFICATES
                  </a>
                </p>

                <p>
                  {" "}
                  <a
                    style={{ textDecoration: "none", color: "#FFF" }}
                    href="/CONTACT"
                  >
                    Contact
                  </a>
                </p>
                <p>
                  {" "}
                  <a
                    style={{ textDecoration: "none", color: "#FFF" }}
                    href="/Gallery"
                  >
                    Gallery
                  </a>
                </p>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
          </Box>
        </div>
      </div>
      <Grid
        style={{ background: "#F7F7F7", padding: ".5%" }}
        class="headingCenter"
      >
        © LAMAX Medical Limited. All Rights Reserved
      </Grid>
    </div>
  );
}

export default Footer;
