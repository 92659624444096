import React from "react";
import { heroWallpaper } from "../data/heroWallpaper";
import GalleryCarousel from "./Gallery/GalleryCarousel";
import GalleryImageGrid from "./Gallery/GalleryImageGrid";
import HeadingType1 from "./HeadingType1";
import HeroWallpaper from "./HeroWallpaper";

export default function Gallery() {
  const heroWallpaperData = heroWallpaper;
  return (
    <div>
      <HeroWallpaper data={heroWallpaperData.pricingPageHeroWallpaper} />
      <div class="container p-5">
        <HeadingType1 data="pricingPageDetails" />
        <div class="row">
          <div className="make-center bold-title pb-5">Recent Events</div>
          <GalleryCarousel />
        </div>
        <div class="row make-center mt-5">
          <div className="make-center bold-title pb-5">Images</div>
          <GalleryImageGrid />
        </div>
      </div>
    </div>
  );
}
